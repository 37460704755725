var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "white-space": "nowrap" } }, [
    _vm.isDarkMode
      ? _c(
          "div",
          { staticClass: "icons", class: { displayInline: _vm.displayInline } },
          [
            _vm._l(_vm.intRating, function(idx) {
              return _c("img", {
                key: _vm.rating.id + "-full-" + idx,
                attrs: {
                  height: "16",
                  width: "16",
                  alt: "star",
                  src: _vm.image(true)
                }
              })
            }),
            _vm._l(5 - _vm.intRating, function(idx) {
              return _c("img", {
                key: _vm.rating.id + "-empty-" + idx,
                attrs: {
                  height: "16",
                  width: "16",
                  alt: "empty star",
                  src: _vm.image(false)
                }
              })
            })
          ],
          2
        )
      : _vm._e(),
    !_vm.isDarkMode
      ? _c(
          "div",
          { staticClass: "icons", class: { displayInline: _vm.displayInline } },
          [
            _vm._l(_vm.intRating, function(idx) {
              return _c("CIcon", {
                key: _vm.rating.id + "-full-" + idx,
                attrs: { name: "cis-star", height: "16", width: "16" }
              })
            }),
            _vm._l(5 - _vm.intRating, function(idx) {
              return _c("CIcon", {
                key: _vm.rating.id + "-empty-" + idx,
                attrs: { name: "cil-star", height: "16", width: "16" }
              })
            })
          ],
          2
        )
      : _vm._e(),
    _vm.showText
      ? _c("span", [
          _c("div", { class: { displayInline: _vm.displayInline } }, [
            _vm._v(_vm._s(_vm.stringRation) + " " + _vm._s(_vm.text))
          ]),
          _vm.ratingCount
            ? _c("div", { class: { displayInline: _vm.displayInline } }, [
                _vm._v(
                  " / " +
                    _vm._s(
                      _vm.$t("components.Rating.ratingCount", {
                        ratingCount: _vm.ratingCount
                      })
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.withRatingDescription && _vm.ratingTranslationKey
            ? _c("div", { class: { displayInline: _vm.displayInline } }, [
                _vm._v(
                  " - " +
                    _vm._s(
                      _vm.$t(
                        "enums.ratingDescriptions." + _vm.ratingTranslationKey
                      )
                    ) +
                    " "
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }